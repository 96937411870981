var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4 pt-4" },
    [
      _c("OptOutPreview", {
        attrs: {
          "opt-out-id": _vm.optOutId,
          email: _vm.email,
          loading: _vm.loading,
          "response-message": _vm.result,
          "is-live": true
        },
        on: { "opt-out-recipient": _vm.optOutRecipient }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }