<template>
  <div class="px-4 pt-4">
    <OptOutPreview
      :opt-out-id="optOutId"
      :email="email"
      :loading="loading"
      :response-message="result"
      :is-live="true"
      @opt-out-recipient="optOutRecipient"
    />
  </div>
</template>

<script>
import { mailingService } from '@/shared/services'
import mailing from '../../../store/modules/mailing'

export default {
  name: 'OptOutRecipient',

  components: {
    OptOutPreview: () => import('@/views/pages/components/mailing/OptOutPreview')
  },

  props: {
    mailingId: {
      type: Number,
      default: null
    },
    recipientId: {
      type: String,
      default: null
    },
    jobId: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      optOutId: null,
      loading: false,
      result: null
    }
  },

  async created () {
    this.loading = true
    this.optOutId = await mailingService.getOptOutIdAcoustic(this.mailingId)
    setTimeout(() => (this.loading = false), 100)
  },

  methods: {
    async optOutRecipient () {
      this.result = null
      var resp = await mailingService.optOutRecipient({
        mailingId: parseInt(this.mailingId),
        recipientId: this.recipientId,
        jobId: this.jobId
      })
      if (resp.success && !resp.errorResponse) {
        this.result = 'You have been unsubscribed!'
      } else {
        this.result = 'Could not process the request. Please try again later'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
